<template>
	<div>
		<profile-form :profile="currentAccount"></profile-form>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import ProfileForm from './forms/ProfileForm';

export default {

	name: 'ProfilePage',

	computed: mapGetters([ 'currentAccount' ]),

	components: {
		ProfileForm,
	},

};

</script>
