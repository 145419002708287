<template>
	<div class="form-wrapper page-has-nav small">
		<div class="form-container">
		<form action="/users" method="get" @submit.prevent="save">
			<form-errors :errors="errors"></form-errors>

			<div v-if="showSuccess" class="alert alert-good">
				<ul>
					<li><span class="fal fa-thumbs-up"></span>Profile updated</li>
				</ul>
			</div>

			<field-errors v-if="submitAttempted" :form="$v.form" field="first_name"></field-errors>
			<div class="input-wrapper">
				<div class="input-title">First Name</div>
				<div class="input-box"><input type="text" v-model="form.first_name"></div>
			</div>
			<field-errors v-if="submitAttempted" :form="$v.form" field="last_name"></field-errors>
			<div class="input-wrapper">
				<div class="input-title">Last Name</div>
				<div class="input-box"><input type="text" v-model="form.last_name"></div>
			</div>

			<div v-if="profile.type === 'STANDARD'">
				<!-- Standard Auth -->
				<field-errors v-if="submitAttempted" :form="$v.form" field="email"></field-errors>
				<div class="input-wrapper">
					<div class="input-title">Email Address</div>
					<div class="input-box"><input type="text" v-model="form.email"></div>
				</div>
				<div v-if="form.email != original.email" class="alert alert-neutral">
					<ul>
						<li><span class="fa fa-flag"></span>You will need to verify {{ form.email }} before your email is actually changed.</li>
					</ul>
				</div>
				<div v-if="original.pending_email_change" class="alert alert-neutral">
					<ul>
						<li><span class="fa fa-flag"></span>There is an email change pending for {{ original.pending_email_change }}. Please check your inbox.</li>
					</ul>
				</div>
			</div>

			<div v-if="profile.type !== 'STANDARD'">
				<!-- Social Auth -->
				<input-tooltip>
					<span slot="title">Email Address</span>
					<div class="input-title">Email Address</div>
					<div slot="field"><input type="text" :value="form.email" disabled readonly></div>
					<div slot="tooltip">
						Your email address was set by using social authentication and cannot be changed.
					</div>
				</input-tooltip>
			</div>


			<field-errors v-if="submitAttempted" :form="$v.form" field="phone_number"></field-errors>
			<div class="input-wrapper">
				<div class="input-title">Phone Number</div>
				<div class="input-box"><input type="text" v-model="form.phone_number" placeholder="321-555-5273" v-mask="['(###) ###-####']"></div>
			</div>
			<submit-button text="Save Changes" error-text="Failed: See Problems Above" icon="fa-check-circle" :loading="formSaving"></submit-button>
		</form>
		</div>

		<div class="form-container">
			<delete-button text="Delete Account" @deleted="deleteAccount()"></delete-button>
		</div>
	</div>
</template>

<script>

import { mapGetters } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import FormMixin from '@/mixins/FormMixin';
import DeleteButton from '@/views/components/DeleteButton';

export default {

	props: [ 'profile' ],

	mixins: [ FormMixin ],

	data() {
		return {
			original: Object.assign({}, this.profile || {}),
			form: Object.assign({}, this.profile || {}),
			showSuccess: false,
			deleting: false,
		};
	},

	computed: mapGetters(['accountType']),

	validations: {
		form: {
			first_name: { required },
			last_name: { required },
			email: { required, email },
		}
	},

	watch: {
		profile: function(newValue) {
			this.original = Object.assign(this.original, newValue || {});
		}
	},

	methods: {

		async save() {
			this.submit(async () => {
				const { data: payload } = await this.$api.post('/user', this.form);

				this.showSuccess = true;
				this.submitAttempted = false;

				this.$store.dispatch('setUser', { user: payload, type: this.accountType });
				this.original = Object.assign({}, payload);
				this.form = Object.assign({}, payload);
			});
		},

		async deleteAccount() {
			if (this.deleting) return;

			this.deleting = true;
			try {
				await this.$api.post('/user/delete');
				this.$store.dispatch('errorToast', 'Account Deleted');
				this.$store.dispatch('logout');
				this.$nextTick(() => {
					this.$router.push('/login');
				});
			} catch (e) {
				this.$store.dispatch('errorToast', 'There was an issue deleting your account');
			}

			this.deleting = false;
		},

	},

	components: {
		DeleteButton,
	},

};

</script>
